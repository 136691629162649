import { css } from '@emotion/css';
import { BackgroundColor, globalHeaderHeight, m } from '@snapchat/snap-design-system-marketing';

export const liveEventWrapperCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Live state background color should cover the entire screen including header, so push this component
  up by the header height so that the background shows through the transparent header */
  margin-top: -${globalHeaderHeight}px;

  min-height: 100vh;
  min-height: 100dvh;
`;

export const darkModeCss = css`
  background-color: ${BackgroundColor.Black};
  color: ${m('--fg-color')};
`;
