import { type FC, useContext } from 'react';

import { AppContext } from '../../../../AppContext';
import { Redirect } from '../../../../components/Redirect/Redirect';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import type { CVentMetadata, ExperienceSiteData } from '../../types';
import type { CventAuthenticatorQueryProps } from './CventAuthenticatorQuery';
import { cventAuthenticatorQuery } from './CventAuthenticatorQuery';

/**
 * A component that checks if the user has a valid registration token and redirects them to the
 * provided URL if they do not.
 *
 * Note: If this component is rendered during client side rendering, page content will briefly flash
 * before the redirect occurs.
 */
export const CventAuthenticator: FC<{ id: string }> = props => {
  const siteData = useContext(AppContext).siteData as ExperienceSiteData<CVentMetadata>;

  const { data } = useContentfulQuery<CventAuthenticatorQueryProps, ContentfulIdVariable>(
    cventAuthenticatorQuery,
    {
      variables: {
        id: props.id,
      },
    }
  );

  if (!data) return null;

  // If a valid registration token is present in the URL, then the site data will have been populated
  // with the event ID the token is associated with as well as user's email, first name, attendance status,
  // and Snapchat username. If the guest is unregistered, the event ID does not match the ID given
  // in Contentful, or the  site data is simply not present, then the user will be redirected to the
  // "unauthenticated slug".
  const shouldRedirect =
    !siteData.isRegistered || siteData.eventMetadata?.eventId !== data.cventAuthenticator.eventId;
  const redirectUrl = `/${data.cventAuthenticator.unauthenticatedSlug.slug}`;

  return shouldRedirect ? <Redirect path={redirectUrl ?? '/'} /> : null;
};
