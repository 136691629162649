import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../../../types/contentful';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const fragment = gql`
  fragment ZkipsterAuthenticatorAll on ZkipsterAuthenticator {
    eventId
    guestListId
    unauthenticatedSlug {
      ...ContentfulSysId
      slug
    }
    ...ContentfulSysId
  }
  ${contentfulSysIdFragment}
`;

export const zkipsterAuthenticatorQuery = gql`
  query ZkipsterAuthenticatorQuery($preview: Boolean!, $locale: String!, $id: String!) {
    zkipsterAuthenticator(preview: $preview, locale: $locale, id: $id) {
      ...ZkipsterAuthenticatorAll
    }
  }
  ${fragment}
`;

export interface ZkipsterAuthenticatorQueryProps {
  zkipsterAuthenticator: ZkipsterAuthenticatorDataProps;
}

export interface ZkipsterAuthenticatorDataProps
  extends ContentfulTypedSysProps<'ZkipsterAuthenticator'> {
  eventId: string;
  guestListId: string;
  unauthenticatedSlug: {
    slug: string;
  };
}
